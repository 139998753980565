import { Injectable, Inject } from '@angular/core';
import { SplitIOService, NgKLoggerService } from 'lib-kinponents';
import { WINDOW } from '@ng-web-apis/common';
import { FullstoryService } from './fullstory.service';

interface SplitFeatureConfig {
  treatment: string;
  config: string;
}

interface RedirectUrlConfig {
  redirectUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class QuotingUiRedirectService {
  private static readonly REDIRECT_TREATMENT_V2 = 'QT-697-ar-quoting-slow-rollout-v2';

  constructor(
    private splitIOService: SplitIOService,
    private fullstoryService: FullstoryService,
    private readonly logger: NgKLoggerService,
    @Inject(WINDOW) private window: Window
  ) {}

  /**
   * Checks if user should be redirected to new experience and performs redirect if appropriate
   * Returns true if redirect was successful, false otherwise
   */
  public tryRedirectToNewUI(): boolean {
    try {
      const featureConfig = this.getSplitFeatureConfig();

      this.fullstoryService.sendABTestEvent(
        QuotingUiRedirectService.REDIRECT_TREATMENT_V2,
        featureConfig?.treatment
      );

      if (!this.shouldRedirectUserToQuotingUI(featureConfig)) {
        return false;
      }

      const urlConfig = this.extractRedirectUrlFromConfig(featureConfig.config);
      if (!urlConfig?.redirectUrl) {
        return false;
      }

      return this.redirectWithQueryParams(urlConfig.redirectUrl);
    } catch (error) {
      this.logError('Unexpected error during redirect attempt');
      return false;
    }
  }

  private getSplitFeatureConfig(): SplitFeatureConfig | null {
    try {
      const config = this.splitIOService.getTreatmentWithConfig(
        QuotingUiRedirectService.REDIRECT_TREATMENT_V2
      ) as SplitFeatureConfig;
      return config || null;
    } catch {
      this.logError('Failed to retrieve split configuration');
      return null;
    }
  }

  private shouldRedirectUserToQuotingUI(featureConfig: SplitFeatureConfig | null): boolean {
    if (!featureConfig) {
      return false;
    }

    let parsedConfig: RedirectUrlConfig;

    try {
      parsedConfig = JSON.parse(featureConfig.config) as RedirectUrlConfig;
    } catch {
      this.logError('Failed to parse redirect URL configuration');
      return false;
    }

    return parsedConfig.redirectUrl !== null;
  }

  private extractRedirectUrlFromConfig(configString: string): RedirectUrlConfig | null {
    try {
      return JSON.parse(configString) as RedirectUrlConfig;
    } catch {
      this.logError('Failed to parse redirect URL configuration');
      return null;
    }
  }

  private redirectWithQueryParams(redirectUrl: string): boolean {
    try {
      const currentUrl = new URL(this.window.location.href);
      const newUrl = new URL(redirectUrl);

      // Preserve the pathname from current URL
      newUrl.pathname = currentUrl.pathname;

      // Copy all query params
      currentUrl.searchParams.forEach((value, key) => {
        newUrl.searchParams.set(key, value);
      });

      this.window.location.href = newUrl.toString();
      return true;
    } catch {
      this.logError('Failed to perform redirect');
      return false;
    }
  }

  private logError(message: string): void {
    try {
      this.logger.error({
        message,
        priority: 'P3',
        context: 'QuotingUiRedirectService',
      });
    } catch {
      // Swallow logger errors - logging failure shouldn't affect redirect behavior
    }
  }
}
